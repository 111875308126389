//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { VsaList, VsaItem, VsaHeading, VsaContent, VsaIcon } from 'vue-simple-accordion';
import SocialSharer from './SocialSharer.vue';

export default {
    name: 'AccordionConstructor',
    components: {
        SocialSharer,
        VsaList,
        VsaItem,
        VsaHeading,
        VsaContent,
        VsaIcon,
    },
    props: ['data', 'headline', 'footerText', 'sharer', 'headlineType'],
    data() {
        return {
            activeItem: -1,
        };
    },
    computed: {
        slots() {
            let slotArr = this.$slots;
            return Object.values(slotArr).map((slot) => slot?.[0]?.data?.attrs?.title);
        },
    },
};
